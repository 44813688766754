import React from 'react';


export default function OES() 
{
    return(
        <div className="AboutUs" style={{position:'relative'}}>
        <div className="container-fluid mb-5" style={{background:'lightgrey',paddingTop:'20px',paddingBottom:'20px'}}>
        <div className="row">
         <div className='row' style={{background:'rgb(2, 2, 36)',padding:'1%',color:'rgb(219, 161, 84)'}}>
              <h2 style={{}}>Owner’s Engineering Services</h2><br /></div>
          <div className="col-12 mx-auto text-center">
            
              <div style={{textAlign:'left',alignItems:'center',display:'block',fontSize:'15px'}}>
              An owner’s engineer (sometimes called lender’s engineer) serves as an independent representative or advocate of the project owner. It is a critical supporting role that provides project management and quality assurance support during all stages of the asset lifecycle.

High quality in the planning and construction of a plant is important for its safety and long-term reliability. Engaging an independent owner’s engineer can ensure that your project complies with international standards, thereby reducing the risk of liabilities and address potential quality issues. In addition, it can also help to reduce your project’s costs through greater control over the project scope, schedule, orders, and various other aspects.
              </div>
            </div>
              </div>
              </div>
              </div>
            
              
        
    )
}