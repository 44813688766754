import React from 'react';


export default function AboutUs() 
{
    return(
        <div className="AboutUs" style={{position:'relative'}}>
        <div className="container-fluid mb-5" style={{background:'lightgrey',paddingTop:'20px',paddingBottom:'20px'}}>
         <div className="row" style={{background:'',marginLeft:'-50px',color:'rgb(2, 2, 36)'}}>
        
             
          <div className="col-10 mx-auto ">
          <h1 style={{paddingTop:'15px',paddingLeft:'0px',color:'navy'}}>ABOUT US</h1><br /> 
              <div style={{textAlign:'left',alignItems:'left',display:'block',fontSize:'15px'}}>
              Everyday around the world, customers come to KVGGN Synergy with questions. “Can we make it better,
            faster, more efficient and sustainable? How do we strike the balance between quality, profitability and
            sustainability?” Beyond solving problems, KVGGN Synergy is dedicated to adding tangible economic value
to our customers. That is why <strong> “Choose certainty. Add value.” </strong>is the core philosophy behind everything that
we do. We partner our customers with early consultation and continuous guidance to make sustainable
progress a reality.<br/><br/>

Headquartered in a <strong>Patna, Bihar </strong>our community of experts is passionate about technology and is inspired by
the possibilities of your business. United by the belief that technology should better people’s lives, we work
alongside our customers to optimize their operations, enable them to access global markets and enhance their
competitiveness.
              </div>
            </div>
              </div>
              </div>
              </div>
            
              
        
    )
}