import React from 'react';
import * as FiBars from "react-icons/fi";
import * as ImBars from "react-icons/im";
import * as BsBars from "react-icons/bs";



export default function ContactUs() 
{
    return(
        <div className="AboutUs" style={{position:'relative'}}>
        <div className="container-fluid mb-5" style={{background:'lightgrey',paddingTop:'20px',paddingBottom:'20px'}}>
        <div className="row" style={{background:'',marginLeft:'-50px',color:'rgb(2, 2, 36)'}}>
        
             
          <div className="col-10 mx-auto " style={{fontSize:'15px'}}>
          <h1 style={{paddingTop:'15px',paddingLeft:'0px',color:'navy'}}>CONTACT US</h1><br /> 
             
          <div className="row">
  
          <div className="col-4 mx-auto ">
          <FiBars.FiPhoneCall style={{height:'30px',width:'30px',marginRight:'30px'}}/>
          <span >Reach out to Us for your queries : </span>
          </div>

          <div className="col-8 mx-auto " style={{textAlign:'left'}}>
          <span ><strong>9081725215/9408299368/9430950377</strong></span>
          </div><br /><br />
          </div>  <br /><br />
          <div className="row">
          <div className="col-4 mx-auto ">
          <ImBars.ImOffice style={{height:'30px',width:'30px',marginRight:'30px'}}/>
          <span>Visit the helpdesk at any of our branches or drop us a line at our 
           our Branch: </span></div>
          <div className="col-8 mx-auto " style={{textAlign:'left'}}>
          <span><strong>204A Atlantic K10 Sara Bhai Road WadiVadi Vadodara (Gujarat)-390017 </strong></span></div></div>
          <br /><br />

          <div className="row">
  
  <div className="col-4 mx-auto " style={{paddingLeft:'-20%'}}>
  <BsBars.BsPencilSquare style={{height:'30px',width:'30px',marginRight:'30px'}}/>
  <span style={{textAlign:'left'}}>Write to us at: </span>
  </div>

  <div className="col-8 mx-auto " style={{textAlign:'left'}}>
  <span ><strong>info@kvggn.in</strong></span>
  </div><br /><br /><br />
  </div>  
          </div>
           </div>
            </div>
              </div>
            
            
              
        
    )
}