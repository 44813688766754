
import './App.css';
//import TopHeader1 from './Component/TopHeader/TopHeader1';
import MainHeader from './Component/TopHeader/MainHeader'
import TopHeader from './Component/TopHeader/TopHeader';
import Home from './Component/Home/Home';
import Footer from './Component/Footer/Footer';
import {BrowserRouter as Router,Route,Switch} from 'react-router-dom';
import Services from './Component/Services/Services';
import AboutUs from './Component/AboutUs/AboutUs';
import ContactUs from './Component/ContactUs/ContactUs';
import EPC from './Component/Services/EPC/EPC';
import Inspection from './Component/Services/Inspection Services';
import WTG_installation from './Component/Services/WTG Installation'
import Site_feasibility from './Component/Services/Site Feasibility Assessment'
import ONM_Services from './Component/Services/ONM_Services '
import Owners_engineers from './Component/Services/OES'
import Warranty_Inspections from './Component/Services/Warranty Inspections'
import Thermography_review from './Component/Services/Thermography'
import Drone_inspection from './Component/Services/Drone'
import Consultancy from './Component/Services/Consultancy'
import Manpower from './Component/Services/Manpower';
import Underground_Fault from './Component/Services/Underground'
import career from './Component/Career/career';
import Dashboard from './Dashboard/Dashboard';
import Item1 from './Dashboard/Item1';
//import Carousel from './Dashboard/Customers';
//import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
//import "../node_modules/bootstrap/dist/js/bootstrap.bundle";


function App() {
  return (
    <div className="App">
           
<TopHeader />      
<MainHeader />  
<Router>
      <Switch>
{/*      
     // <Route exact path='/' component={Dashboard}></Route> */}
      <Route exact path='/Home' component={Dashboard}></Route>

      <Route exact path='/Services' component={Services}></Route>
      <Route exact path='/AboutUs' component={AboutUs}></Route>
      <Route exact path='/ContactUs' component={ContactUs}></Route>
      <Route exact path='/career' component={career}></Route>
      <Route exact path='/EPC' component={EPC}></Route>
      <Route exact path='/Inspection' component={Inspection}></Route>
      <Route exact path='/WTG_installation' component={WTG_installation}></Route>
      <Route exact path='/Site_feasibility' component={Site_feasibility}></Route>
      <Route exact path='/ONM_Services' component={ONM_Services}></Route>
      <Route exact path='/Owners_engineers' component={Owners_engineers}></Route>
      <Route exact path='/Warranty_Inspections' component={Warranty_Inspections}></Route>
      <Route exact path='/Thermography_review' component={Thermography_review}></Route>
      <Route exact path='/Drone_inspection' component={Drone_inspection}></Route>
      <Route exact path='/Consultancy' component={Consultancy}></Route>
      <Route exact path='/Manpower_Outsourcing' component={Manpower}></Route>
      <Route exact path='/Underground_Fault' component={Underground_Fault}></Route>
      <Route exact path='/Item1' component={Item1}></Route>


     
      
      



      <Route exact path='/' component={Dashboard}></Route>

      <Route exact path='/Home' component={Home}></Route>

      </Switch>
    </Router>

<Footer />  
     
     
    </div>
  );
}

export default App;


