import React,{useState,ToggleButtonGroup,ToggleButton} from 'react';

import Carousel from 'react-bootstrap/Carousel'
import img1 from './Solar1.jpg';
import img2 from './Wind2.jpg';
import img3 from './Wind3.jpg';
import img4 from './inspection.jpg'; 


export default function Home()
{
    return(
      <div>
      
        <Carousel>
  <Carousel.Item interval={2000}>
    <img
      className="d-block w-100"
      src={img3} height='600px' width='40%'
      alt="First slide"
    />
    <Carousel.Caption>
      <h1>Renewable Energy </h1>
      <p style={{fontSize:'30px',color:'orange'}}>Wind Power Energy Solutions</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item interval={2000}>
    <img
      className="d-block w-100"
      src={img1} height='600px' width='40%'
      
      alt="Second slide"
    />
    <Carousel.Caption>
      <h1>Solar Energy</h1>
      <p style={{fontSize:'30px',color:'orange'}}>Solar Power Energy Solutions</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item interval={2000}>
    <img
      className="d-block w-100"
      src={img2} height='600px' width='40%'
      alt="Third slide"
    />
    <Carousel.Caption>
      <h1 >Wind Turbine</h1>
      <p style={{fontSize:'30px',color:'red'}}>Erection and Comissioning Services</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item interval={2000}>
    <img
      className="d-block w-100"
      src={img4} height='600px' width='40%'
      alt="Inspection slide"
    />
    <Carousel.Caption>
      <h1 >Quality check Inspections</h1>
      <p style={{fontSize:'30px',color:'red'}}>Inspection Services</p>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>
</div>
 );
}

