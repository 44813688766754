import React from 'react';


export default function Site_feasibility() 
{
    return(
        <div className="AboutUs" style={{position:'relative'}}>
        <div className="container-fluid mb-5" style={{background:'lightgrey',paddingTop:'20px',paddingBottom:'20px'}}>
        <div className="row">
         <div className='row' style={{background:'rgb(2, 2, 36)',padding:'1%',color:'rgb(219, 161, 84)'}}>
              <h2 style={{}}>Site Feasibility Assessment</h2><br /></div>
          <div className="col-12 mx-auto text-center">
            
              <div style={{textAlign:'left',alignItems:'center',display:'block',fontSize:'15px'}}>
              Conducting Site and Economic Project Feasibility Assessments Evaluating the site and economic feasibility of a any project is an essential step in the development process and should be completed in the initial stages, prior to preparing a system design, entering into contracts, or purchasing equipment. Various tools and resources are available to the public for analyzing key project aspects such as site viability, resource potential, system performance, and cost estimates.
              <br /> We at KVGGN offer you our services for site feasbiltiy assesment for any project in very initial stage.
              </div>
            </div>
              </div>
              </div>
              </div>
            
              
        
    )
}