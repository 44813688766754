import React from 'react';
import img1 from './thermography.jpg';
import img2 from './Th1.png';
import img3 from './co1.png';
import img4 from './co2.png';




export default function EPC() 
{
    return(
        <div className="AboutUs" style={{position:'relative'}}>
        <div className="container-fluid mb-5" style={{background:'lightgrey',paddingTop:'20px',paddingBottom:'20px'}}>
        <div className="row">
         <div className='row' style={{background:'rgb(2, 2, 36)',padding:'1%',color:'rgb(219, 161, 84)'}}>
              <h2 style={{}}>Thermography & Corona-graphy</h2><br /></div>
          <div className="col-12 mx-auto text-center">
            
              <div style={{textAlign:'left',alignItems:'center',display:'block',fontSize:'15px'}}>
              We at KVGGN offer Thermovision Hot spot scanning /Corona graphy inspection services that are designed to locate potential electrical problems and assist utilities with eliminating failures in transmission, distribution and substation systems. The use of thermography cameras/Corona Graphy for power line infrared inspection provides the fastest and most accurate method of survey.<br /> <br />We have an  experienced team along with enhanced high resolution equipment that allows us to locate potential problems quickly and accurately..
              <br />
              <br />
              <div className="row">
            
              <div className="col-2 mx-auto text-center">
               <img src={img1} alt='logo'  width='85%'/>
               </div>
               <div className="col-2 mx-auto text-center">
               <img src={img2} alt='logo'  width='135%'/>
               </div>
               <div className="col-2 mx-auto text-center">
               <img src={img3} alt='logo'  width='85%'/>
               </div>
               <div className="col-2 mx-auto text-center">
               <img src={img4} alt='logo'  width='85%'/>
               </div>
               </div>
               <div className='row' style={{marginTop:'0%',color:'rgb(2, 2, 36)'}}>
               <div className="col-2 mx-auto text-center">
               <p><strong>Substation Thermography</strong></p>
               </div>
               <div className="col-2 mx-auto text-center">
               <p><strong>TowerLine HotSpot Scanning</strong></p>               </div>
               <div className="col-2 mx-auto text-center">
               <p><strong>Corona Graphy  </strong></p>               </div>
               <div className="col-2 mx-auto text-center">
               <p><strong>EHV Coronagraphy  </strong></p>               </div>
               
              </div>
               </div>
            </div> </div>
            </div>
              </div>
             
              
            
              
        
    )
}