import React from 'react';
import img1 from './rd_1.svg';
import img2 from './rd_2.svg';
import img3 from './rd_3.svg';
import img4 from './rd_4.svg';
import img5 from './rd_5.svg';


export default function EPC() 
{
    return(
        <div className="AboutUs" style={{position:'relative'}}>
        <div className="container-fluid mb-5" style={{background:'lightgrey',paddingTop:'0px',paddingBottom:'0px'}}>
         <div className="row">
         <div className='row' style={{background:'rgb(2, 2, 36)',padding:'1%',color:'rgb(219, 161, 84)'}}>
              <h2 style={{}}>EPC Services</h2><br /></div>
          <div className="col-12 mx-auto text-center">
            
              <div style={{textAlign:'left',alignItems:'center',display:'block',fontSize:'15px'}}>
              Engineering, Procurement & Construction (EPC) services refer to a form of contracting arrangement used in the construction and engineering industries, when a customer hires a contractor to perform the entire work cycle. The Contractor, within budget, performs the work, manages the project and arranges for the work of sub-contractors.

The EPC contract is concluded between the customer and the contractor. Under the contact the contractor bears all risks and is tasked with the execution of the full scope of works for the project.
<br /><br />

Benefits of EPC Services contracts:
<ul>
  <li> 1. Fixed budget and timeline</li><br/>
   <li>2. Contractor is responsible for management of all the activities within the construction project chain</li><br/>
 <li> 3. Efficient project management as a one contact.</li><br/>
 </ul><br/>
    At KVGGN we offer complete solutions for  <br /><br />
    </div>
          <div className='row'>
               <div className="col-2 mx-auto text-center">
               <img src={img1} alt='logo'  width='50%'/>
               </div>
               <div className="col-2 mx-auto text-center" >
               <img src={img2} alt='logo'  width='50%' style={{paddingTop:'10px'}}/>
               </div>
               <div className="col-2 mx-auto text-center">
               <img src={img3} alt='logo'  width='50%'/>
               </div>
               <div className="col-2 mx-auto text-center">
               <img src={img4} alt='logo'  width='50%'/>
               </div>
               <div className="col-2 mx-auto text-center">
               <img src={img5} alt='logo'  width='50%'/>
               </div>
              </div>
              <div className='row'>
               <div className="col-2 mx-auto text-center">
               <p><strong>Design Engineering</strong></p>
               </div>
               <div className="col-2 mx-auto text-center">
               <p><strong>Procurement and Supply of Materials and Equipment</strong></p>               </div>
               <div className="col-2 mx-auto text-center">
               <p><strong>Installation</strong></p>               </div>
               <div className="col-2 mx-auto text-center">
               <p ><strong>Commissioning and Start-up</strong></p>               </div>
               <div className="col-2 mx-auto text-center">
               <p><strong>Maintenance</strong></p>               </div>
              </div>
            </div>
              </div>
              </div>
              </div>
            
              
        
    )
}