import React from 'react';
import img1 from './cab1.jpg';


export default function EPC() 
{
    return(
        <div className="AboutUs" style={{position:'relative'}}>
        <div className="container-fluid mb-5" style={{background:'lightgrey',paddingTop:'20px',paddingBottom:'20px'}}>
        <div className="row">
         <div className='row' style={{background:'rgb(2, 2, 36)',padding:'1%',color:'rgb(219, 161, 84)'}}>
              <h2 style={{}}>Underground Fault Locator</h2><br /></div>
          <div className="col-12 mx-auto text-center">
            
              <div style={{textAlign:'left',alignItems:'center',display:'block',fontSize:'15px'}}>
              We carry out cable fault location on either overground or underground cables and provide an engineer to carry out safe electrical system switching who cantake control of the supply and identify the location of the fault.
              <br />
              <br /> At  KVGGN we offer a comprehensive solution, round the clock, combined with testing, fault locating and physical rectification of the underground cable faults including supply of cable jointing/ termination kits. We have an in house team of the certified jointers for making the cable joints up to MV voltage level.

This enable our clients to achieve the quick restoration of power supply without investing heavily in procuring the cable fault location systems as well as maintaining inventory of cable repair materials such as jointing kits, termination kits and so on and reduces manpower burden from their operations.
<div className="row">
            
               <img src={img1} alt='logo'  width='100%'/>
               </div>
              </div>
              </div>
            </div>
              </div>
              </div>
              
            
              
        
    )
}