import React, { useState } from "react";
import logo from './kvggn-logo1.png';

import "./MainHeader.css";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaYoutubeSquare,
} from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";

//import { NavLink } from "react-router-dom";

const MainHeader = () => {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  return (
    <div>





      <nav className="main-nav" style={{background:'white'}}>
        {/* 1st logo part  */}
        <div className="logo" >
        <img src={logo} id="logo" alt="TCS Logo" height="70px" width='120px' style={{ marginTop:'-5px',marginBottom:'-16px',color:"white", fontSize:"15px"}}/>
  <div style={{marginTop:'0px',fontSize:'15px',marginLeft:'120px',marginTop:'-40px',color:'navy'}}>"always deliver more than Expected"</div>

        </div>

        {/* 2nd menu part  */}
        <div
          className={
            showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
          } id="options">
          <ul>
            <li>
              <a href="/" style={{color:'navy'}}>Home</a>
            </li>
            <li>
              <a href="/AboutUs" style={{color:'navy'}}>About US</a>
            </li>
            <li>
              <a href="/Services" style={{color:'navy'}}>Services</a>
            </li>
            <li>
              <a href="/ContactUs" style={{color:'navy'}}>Contact Us</a>
            </li>
            <li>
              <a href="/career" style={{color:'navy'}}>Career</a>
            </li>
          </ul>
        </div>

        {/* 3rd social media links */}
        <div className="social-media">
        

          {/* hamburget menu start  */}
          <div className="hamburger-menu">
            <a href="#" onClick={() => setShowMediaIcons(!showMediaIcons)}>
              <GiHamburgerMenu />
            </a>
          </div>
        </div>
      </nav>

      {/* hero section  */}
      {/* <section className="hero-section">
        <p>Welcome to </p>
        <h1>Thapa Technical</h1>
      </section> */}
     </div>
  );
};

export default MainHeader;
