import React from 'react';


export default function career() 
{
    return(
        <div className="AboutUs" style={{position:'relative'}}>
        <div className="container-fluid mb-5" style={{background:'lightgrey',paddingTop:'20px',paddingBottom:'20px'}}>
        <div className="row" style={{background:'',marginLeft:'-50px',color:'rgb(2, 2, 36)'}}>
        
             
        <div className="col-10 mx-auto " style={{fontSize:'15px'}}>
        <h1 style={{paddingTop:'15px',paddingLeft:'0px',color:'navy'}}>CAREER</h1><br /> 
            
              <div style={{textAlign:'left',alignItems:'center',display:'block'}}>

               <h4 style={{textAlign:'left'}} ><strong>WORK @KVGGN</strong></h4><br /> We believes in nurturing talent by providing a conducive environment to grow. We aim to provide a flexible & collaborative work environment that engages employees allowing them to express and experience a sense of meaning and belonging. We value Open Communication and Flat Hierarchy. We encourage Diversity of thought, gender, age, industry mix, region to ensure comprehensive and constructive decision making process.
               <br /><br />The company endeavours to provide an environment where the employees experience a balance of performance meritocracy, professional aspiration fulfilment and compliance. The company policies are forward looking and enable to provide an atmosphere where the Individuals can focus on work deliverables while their key professional necessities are taken care off with well-defined guidelines. Our Value Proposition today is defined around - ‘Wellness’ and ‘Internal Work Opportunities’ at every level.<br /><br />

              <h5 style={{fontSize:'15px'}}> If you are interested in working with us, <strong style={{color:"rgb(219, 161, 84)"}}>Send your Resume/CV.... <span style={{color:"rgb(2, 2, 36)"}}> kvggn5@gmail.com</span></strong></h5>
              </div>
            </div>
              </div>
              </div>
              </div>
            
              
        
    )
}