import React from 'react';
import {Form,FormControl,Button} from "react-bootstrap";
import { FaFacebookSquare,FaTwitterSquare ,FaLinkedin,FaBars,FaInstagramSquare} from 'react-icons/fa';


function Footer()
{
    return(
        <div style={{position:'relative',bottom:0,width:"100%"}}>
        <div className="Footer" style={{padding:"10px",backgroundColor:"pink"}}>
          
        <div className="container-fluid ">
           <div className='row'>
              <div className="col-md-11" style={{marginLeft:"4%"}}>
              <div className="row">
              <div className= "col-md-4 " style={{textAlign:'left'}}>
                  <h4 style={{fontSize:"15px"}}><strong>HEAD OFFICE ADDRESS</strong></h4>
                  <span style={{fontSize:"13px",paddingTop:"40px"}}>204 A, Atlantic K10, Sara Bhai compound <br />WadiVadi Vadodara   <br />   Vadodara (Gujarat) -390017 <br />                                                                                                                                                                                                   </span>
                         
              </div><br />

              <div className="col-md-3 " style={{textAlign:'text-center'}}>
              <h4 style={{fontSize:"15px"}}><strong>BRANCH ADDRESS</strong></h4>
              <span style={{fontSize:"13px",paddingTop:"40px"}}>House No - 623 <br /> Balramnagar Society <br /> Near Raghuvasnhi chaowkdi <br /> Bhuj (Gujarat) </span>
               </div><br />

               <div className="col-md-3 " style={{textAlign:'left',marginLeft:'%'}}>
              <h4 style={{fontSize:"15px"}}><strong>USEFUL LINKS</strong></h4>
              <li>
              <a
                href="https://www.facebook.com/KVGGN/"
                target="blank">
                <FaFacebookSquare className="facebook1" size="50" color="navy"/>
              </a>
            </li>
           
            <li>
              <a
                href="https://instgram.com/kvggn5/"
                target="blank">
                <FaInstagramSquare className="instagram" size="50" color="red"/>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/kvggn5/"
                target="blank">
                <FaLinkedin className="youtube" size="50" color="green"/>
              </a>
            </li>
               </div><br />
               </div>
               </div>
               </div>
               </div>

              
               
               
            
        </div>

<div className="bottom_footer" style={{marginLeft:'-2%',backgroundColor:"black", paddingTop:"5px",paddingBottom:"5px",fontSize:'12px'}}>
<div className="container-fluid nav_bg">   
<div className="row" style={{marginLeft:'6%'}}>
<div className="col-md-8 col-10  mx-auto" style={{marginLeft:'%'}}>

<span style={{color:"white",textAlign:'left'}}>@ 2024 All Rights Reserved by-KVGGN SYNERGY PVT. LTD.   </span>

</div>
<div className="col-md-4 col-10 mx-auto">

<span style={{color:"white",textAlign:'left'}}>Developed by - IT team KVGGN   </span>

</div>
</div>
</div>
</div>

  </div>
    );
}

export default Footer;
/*
<div className="bottom_footer" style={{backgroundColor:"black"}}>
<div className="row" >
<div className="col-10 mx-auto">

  <span style={{color:"white"}}>@MBU Azure Edge to Cloud Practice Microsoft Business Unit. Tata Consultancy Services Ltd. </span>

</div>
</div>
</div>
*/