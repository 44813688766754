import React from 'react';
import img1 from './Wtg1.jpeg';
import img2 from './Wtg2.jpeg';
import img3 from './Wtg3.jpeg';
import img4 from './GWO.png';


export default function WTG_installation() 
{
    return(
        <div className="AboutUs" style={{position:'relative'}}>
        <div className="container-fluid mb-5" style={{background:'lightgrey',paddingTop:'20px',paddingBottom:'20px'}}>
        <div className="row">
         <div className='row' style={{background:'rgb(2, 2, 36)',padding:'1%',color:'rgb(219, 161, 84)'}}>
              <h2 style={{}}>WTG Installation</h2><br /></div>
          <div className="col-12 mx-auto text-center">
            
              <div style={{textAlign:'left',alignItems:'center',display:'block',fontSize:'14px'}}>
              KVGGN team provides full range of installation services for wind turbines, from deliveries on site for major components, to pre-assembly on ground and erection of steel towers, nacelle, rotor hub, generator, blades etc. (depending on turbine manufacturer and type). We have sufficient Resources,Skilled Manpower which is certified and trained by GLOBAL WIND ORGANISATION for Material handling, working at height, First ad & Fire fighting
              <br /> 
              <br /> As a team we have Robust Experience for working in this field from last so many year, We offer you our best services
              </div>

              <br />
              <br />
              <div className="row">
            
              <div className="col-2 mx-auto text-center">
               <img src={img1} alt='logo'  width='80%'/>
               </div>
               <div className="col-2 mx-auto text-center">
               <img src={img2} alt='logo'  width='80%'/>
               </div>
               <div className="col-2 mx-auto text-center">
               <img src={img3} alt='logo'  width='60%'/>
               </div>
               <div className="col-2 mx-auto text-center">
               <img src={img4} alt='logo'  width='130%'/>
               </div>
               </div>
               <div className='row' style={{marginTop:'0%',color:'rgb(2, 2, 36)'}}>
               <div className="col-2 mx-auto text-center">
               <p><strong>Tubine Material Handling</strong></p>
               </div>
               <div className="col-2 mx-auto text-center">
               <p><strong>Wind Turbine Erection</strong></p>               </div>
               <div className="col-2 mx-auto text-center">
               <p><strong>Rotor Erection  </strong></p>               </div>
               <div className="col-2 mx-auto text-center">
               <p><strong>GWO Certified Manpower  </strong></p>               </div>
               
              </div>
               </div>
            </div> </div>
            </div>
              
            
              
        
    )
}