import {React} from 'react';
import logo from './kvggn-logo1.png';
import {Navbar,Nav,NavLink,Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HiMail } from 'react-icons/hi';
import { IoCall } from 'react-icons/io5';
import { FaFacebookSquare,FaTwitterSquare ,FaLinkedin,FaBars,FaInstagramSquare} from 'react-icons/fa';
import { AiFillLinkedin } from 'react-icons/ai';
import './TopHeader.css';
import {Grid,Icon} from 'semantic-ui-react';
import MainHeader from './MainHeader';


export default function TopHeader()
{
    return(
<div className="top_Header" style={{position:'sticky',top:0,width:"100%",zIndex:'999'}}>
<div className="subHeader" style={{display:'block',backgroundColor:"navy",position:'sticky',top:'0px',width:'100%',height:'30px'}}>
<div className="container-fluid  ">
          <div className="row">
            <div className="col-md-12   mx-auto">
              <div className="row">
                <div className="col-md-6 " style={{background:'',marginLeft:'75px',verticalAlign:"center"}}>
               
                  <nav className="navbar navbar-expand-lg ">
                    <Grid columns={2}>
                        <Grid.Row>
                          <Grid.Column style={{marginTop:'0px'}}>
                            <Nav.Link href="#home" style={{color:'rgb(219, 161, 84)', fontSize:"15px",marginTop:'0px'}}><HiMail />info@kvggn.in</Nav.Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                     </Grid.Column>
                    <Grid.Column style={{}}>
                            <Nav.Link href="#link" style={{color:'rgb(219, 161, 84)',fontSize:"15px",marginTop:'-39px',marginLeft:'150px'}}><IoCall /> 9408299368</Nav.Link>
                    </Grid.Column>
                        </Grid.Row>
                  </Grid>
                  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  </nav>
           
             </div>
             <div className="col-md-6  mx-auto" style={{background:''}}> 
              <div className="row">
              <div className="col-md-9"></div>
              <div className="col-md-3  mx-auto" style={{marginLeft:"100px"}}>
              <ul className="navbar-nav mr-auto ">
              <li className="justify-content-end" style={{textAlign:"right"}}>
              <div className="social-media1">
          <ul className="social-media-desktop1" style={{marginTop:'-83px'}}>
            <li>
              <a
                href="https://www.facebook.com/KVGGN/"
                target="blank">
                <FaFacebookSquare className="facebook1" />
              </a>
            </li>
           
            <li>
              <a
                href="https://www.instagram.com/kvggn5/"
                target="blank">
                <FaInstagramSquare className="instagram" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/kvggn5/"
                target="blank">
                <FaLinkedin className="youtube" />
              </a>
            </li>
          </ul>

          {/* hamburget menu start  */}
          {/* <div className="hamburger-menu">
            <a href="#" onClick={() => setShowMediaIcons(!showMediaIcons)}>
              <GiHamburgerMenu />
            </a>
          </div> */}
        </div>
{/*               
                <div className="row">
                  <div className="col-md-1 ">
              <a
                href="https://www.facebook.com/KVGGN/"
                target="/blank">
                <FaFacebookSquare className="facebook1" style={{height:'40px'}}/>
              </a>
                  </div>
                  <div className="col-md-1"> 
              <a href="https://twitter.com/kvggn" target="/blank"style={{color:'rgb(219, 161, 84)'}}><FaTwitterSquare style={{height:'20px'}}/></a>
              </div>
              <div className="col-md-1"> 
              <a href="https://www.linkedin.com/in/kvggn-synergy-7b8841216/" target="/blank"style={{color:'rgb(219, 161, 84)'}}><FaLinkedin  style={{height:'20px'}}/></a>
              </div>
              </div> */}
             
             </li>
              </ul>
              </div>
              </div>
      
            
  </div>

  </div> 
  </div> 
  </div> 
  </div>
  </div> 
  </div>  
 );
}   
  

          
