import {Card} from 'react-bootstrap';

const Cards =(props)=>

{
    
    return(
        
        <div className="col-md-3 col-10 mx-auto">
        <a href={props.href} >
        <Card  hoverable style={{paddingTop:"8px",marginRight:"28px", marginBottom:"20px",width:'255px'}}>
        <Card.Header style={{textAlign:"center",color:'rgb(2, 2, 36)',fontWeight:'regular',fontSize:"16px",width:'254px'}}>{props.title}</Card.Header>
        
          <Card.Img style={{height : '210px', width : '254px'}} variant="top" src={props.imgsrc} />
         
          
       </Card>
       </a> 
       </div>
    
    );
}

export default Cards;
