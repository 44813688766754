import React from 'react';


export default function Inspection() 
{
    return(
        <div className="AboutUs" style={{position:'relative'}}>
        <div className="container-fluid mb-5" style={{background:'lightgrey',paddingTop:'20px',paddingBottom:'20px'}}>
        <div className="row">
         <div className='row' style={{background:'rgb(2, 2, 36)',padding:'1%',color:'rgb(219, 161, 84)'}}>
              <h2 style={{}}>Inspection Services</h2><br /></div>
          <div className="col-12 mx-auto text-center">
            
              <div style={{textAlign:'left',alignItems:'center',display:'block',fontSize:'15px'}}>
              Third Party Inspection services refers to independent inspection services that are provided by inspection agencies either Hired by Seller or buyer for quality control measures,These quality controls and inspections are done based on approved design documents, Purchase Order and International standards.
<br />
<br />
As a team of professionals, which has years of expereience in field of Construction, related to Civil infrastructure, Electrical infrastructure, Wind energy & Solar energy, We offer our services for the inspection services for all over India

              </div>
            </div>
              </div>
              </div>
              </div>
            
              
        
    )
}