import React from 'react';
import img1 from './Man (1).jpg';
import img2 from './Man (2).jpg';
import img3 from './Man (3).jpg';
import img4 from './Man (4).jpg';
import img5 from './Man (5).jpg';


export default function Manpower() 
{
    return(
        <div className="AboutUs" style={{position:'relative'}}>
        <div className="container-fluid mb-5" style={{background:'lightgrey',paddingTop:'0px',paddingBottom:'0px'}}>
        <div className="row">
         <div className='row' style={{background:'rgb(2, 2, 36)',padding:'1%',color:'rgb(219, 161, 84)'}}>
              <h2 style={{}}>Manpower Consulting Services</h2><br /></div>
          <div className="col-12 mx-auto text-center">
            
              <div style={{textAlign:'left',alignItems:'center',display:'block',fontSize:'15px'}}>
              We can provide engineers / Designers / technicians right from conceptualization of the project to Design to Construction to install to Pre commissioning & Commissioning to Operations and Maintenance of the plant.
               We have huge data base for competent Techinians/Engineer/ Supervisior , who can execute Job efficiciently . We also Provide project manager / Inspectors / Safety personnel for smooth function of the projects. we can mobilize manpower for shourt term contract of 15 days to long duration contract
              <br /><br /> We can provide manpower as on required basis <br /><br />
             <div className="row">
            
               <div className="col-2 mx-auto text-center">
               <img src={img1} alt='logo'  width='80%'/>
               </div>
               <div className="col-2  mx-auto text-center">
               <img src={img2} alt='logo'  width='50%'/>
               </div>
               <div className="col-2 mx-auto text-center">
               <img src={img3} alt='logo'  width='80%'/>
               </div>
               <div className="col-2 mx-auto text-center">
               <img src={img4} alt='logo'  width='80%' height='60%'/>
               </div>
               <div className="col-2 mx-auto text-center">
               <img src={img5} alt='logo'  width='80%'/>
               </div>
               </div>

               <div className='row' style={{marginTop:'-4%',color:'rgb(2, 2, 36)'}}>
               <div className="col-2 mx-auto text-center">
               <p><strong>HSE Officer</strong></p>
               </div>
               <div className="col-2 mx-auto text-center">
               <p><strong>Quality Assurance Engineer</strong></p>               </div>
               <div className="col-2 mx-auto text-center">
               <p><strong>Project Execution Team</strong></p>               </div>
               <div className="col-2 mx-auto text-center">
               <p><strong>Skilled Man Power</strong></p>               </div>
               <div className="col-2 mx-auto text-center">
               <p><strong>Technical Trainers</strong></p>               </div>
              </div>

              </div>
            </div>
              </div>
              </div>
              </div>
            
              
        
    )
}