
import wtg from './wtg.jpeg';
import inspection from './Inspection.jpg';
import epc from './EPC.jpeg' ;
import Site from './feas.jpg';
import manpower from './manpower.jpg'
import drone from './drone.jpeg'
import ES from './2.jpeg'
import OnM from './ONM.jpeg'
import Consultancy from './Consultancy.jpg'
import cable from './cable Fault.png'
import Quality from './Quality.JPG'
import Thermo from './thermography.jpg'




const CardData=[
    {
        imgsrc:epc,
        title:"EPC Services",
        href:'/EPC'
    },
    {
        imgsrc:inspection,
        title:"Inspection Services",
        href:'/Inspection'
    },
    {
        imgsrc:wtg,
        title:"WTG Installation",
        href:'/WTG_installation'
    },
    {
        imgsrc:Site,
        title:"Site Feasibility Assessment",
        href:'/Site_feasibility'
    },
    {
        imgsrc:OnM,
        title:"O&M Services",
        href:'/ONM_Services'
    },
    {
        imgsrc:ES,
        title:"Owners Engineers Services",
        href:'/Owners_engineers'
    },
    {
        imgsrc:Quality,
        title:"Hotline Stringing ",
        href:'/Warranty_Inspections'
    },
    {
        imgsrc:Thermo,
        title:"Thermography Review", 
        href:'/Thermography_review'
    },
    {
        imgsrc:drone,
        title:"Drone Inspection",
        href:'/Drone_inspection'
    },
    {
        imgsrc:Consultancy,
        title:"Consultancy",
        href:'/Consultancy'
    },
    {
        imgsrc:manpower,
        title:"Manpower Outsourcing",
        href:'/Manpower_Outsourcing'
    },
    {
        imgsrc:cable,
        title:"Underground Fault Locator",
        href:'/Underground_Fault'
    },
]

export default CardData;