import {React} from 'react';
import Home from '../Component/Home/Home';
import Carousel from 'react-bootstrap/Carousel';
import img1 from './kvggn-logo.JPG';
import img2 from './logo1.jpg';
import img3 from './logo2.png';
import img4 from './logo3.png';
import img5 from './logo4.png';
import img6 from './logo5.png';
import img7 from './logo6.png';
import img8 from './logo7.png';
import img9 from './logo8.png';
import img10 from './logo9.jpg';
import img11 from './logo10.png';
//import Carousel from './Customers';
import Item1 from './Item1';


export default function Dashboard()
{
    return(
       <div className="Dashboard" style={{position:'relative'}}>
       <Home />
        <div className="container-fluid mb-5" style={{background:'white',paddingTop:'20px',paddingBottom:'20px'}}>
         <div className="row">
          <div className="col-10 mx-auto text-center">
              <h3 style={{color:""}}><strong>OUR TRUSTED CUSTOMERS</strong></h3><br /><br />
              
             <div className="row">
                
              


               <div className="col-2 mx-auto text-center">
                <img src={img11} alt='logo'  width='100%'/>
               </div>
               <div className="col-2 mx-auto text-center">
               <img src={img2} alt='logo'  width='115%'/>
               </div>
               <div className="col-2 mx-auto text-center">
               <img src={img3} alt='logo'  width='75%'/>

               </div>&nbsp;
               <div className="col-2 mx-auto text-center">
               <img src={img4} alt='logo'  width='100%'/>

               </div>
               <div className="col-2 mx-auto text-center">
               <img src={img8} alt='logo'  width='100%'/>

               </div>
               <div className="col-2 mx-auto text-center">
               <img src={img7} alt='logo'  width='85%'/>

               </div>
               <div className="col-2 mx-auto text-center">
               <img src={img9} alt='logo'  width='100%'/>

               </div>
               <div className="col-2 mx-auto text-center">
               <img src={img5} alt='logo'  width='110%'/>
               </div>
               <div className="col-2 mx-auto text-center">
               <img src={img10} alt='logo'  width='90%'/>
               </div>
               <div className="col-2 mx-auto text-center">
               <img src={img6} alt='logo'  width='40%'/>

               </div>
               </div>  
               </div>
               </div>
               </div>
               </div>
               
             
             
 );            
 }