import React from 'react';
import CardData from './CardData';
import Cards from './Cards';

export default function Services() 
{
    return(
        <div className="Services" style={{position:'relative'}}>
        <div className="container-fluid mb-5" style={{background:'lightgrey'}}>
        <div className="row" style={{background:'',marginLeft:'-50px',color:'rgb(2, 2, 36)'}}>
        
             
        <div className="col-10 mx-auto ">
        <h1 style={{paddingTop:'15px',paddingLeft:'0px',fontSize:'30px',color:'navy'}}>SERVICES</h1><br /> 
             
            <div className="row gy-1" >
             {CardData.map((val,ind)=>{
                 return <Cards key={ind} title={val.title} imgsrc={val.imgsrc} href={val.href}/>
             })}
           
              </div>
              </div>
              </div>
              </div>
            
              
        </div>
    )
}