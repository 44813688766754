
import React, { useState } from "react";
import ReactDOM from "react-dom";
import Carousel from "react-elastic-carousel";
import "./Item.js";
import "./Item.css";
import img2 from './logo1.jpg';
import img3 from './logo2.png';
import img4 from './logo3.png';
import img5 from './logo4.png';
import img6 from './logo5.png';
import img7 from './logo6.png';
import img8 from './logo7.png';
import img9 from './logo8.png';

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 }
];

export default function Item1() {
  const [items, setItems] = useState([{img2},{img3},{img4},{img5}]);

 

  return (
    <div className="Item1">
      
      <hr className="seperator" />
      <div className="carousel-wrapper">
        <Carousel breakPoints={breakPoints}>
          {items.map((item) => (
            <Item1 key={item}>{item}</Item1>
          ))}
        </Carousel>
      </div>
    </div>
  );
}


